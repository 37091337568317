import React from 'react';
import { connect } from 'react-redux';

import './PlayersArea.css';

import { personRemoved, personChanged } from '../actions/calcActions';

class Player extends React.Component {
    constructor(props) {
        super(props);
        this.paymentChanged = this.paymentChanged.bind(this);
    }

    paymentChanged(evt) {
        evt.stopPropagation();
        this.props.paymentChanged();
    }

    render() {
        const payed = this.props.payed? 'Ja': 'Nein';
        return (
          <tr onClick={ this.props.editPlayer }>
            <td>{ this.props.name }</td>
            <td>{ this.props.beer }</td>
            <td>{ this.props.food }</td>
            <td>{ this.props.total }</td>
            <td onClick={ this.paymentChanged }>{ payed }</td>
          </tr>
        )
    }
}

class PlayersArea extends React.Component {
    constructor(props) {
        super(props);
        this.editPlayer = this.editPlayer.bind(this);
    }

    paymentChanged(index) {
        let data = Object.assign({}, this.props.people[index]);
        data.payed = !data.payed;
        this.props.personChanged(index, data);
    }

    editPlayer(index) {
        this.props.history.push('/calc/edit_player/'+index);
    }

    render() {
        const players = [];
        for (let index in this.props.people) {
            let player = this.props.people[index];
            players.push(
                <Player name={ player.name } food={ player.food_price } total={ player.total_price }
                  beer={ player.beer_count } payed={ player.payed }
                  editPlayer={ () => this.editPlayer(index) } key={ index }
                  paymentChanged={ () => this.paymentChanged(index) } />
            )
        }
        return (
          <table className="style-player-area style-player-table">
            <thead>
              <tr>
                <th>Spieler</th>
                <th>#Bier</th>
                <th>Essen</th>
                <th>Gesamt</th>
                <th>Bezahlt</th>
              </tr>
            </thead>
            <tbody>
               { players }
            </tbody>
          </table>
        )
    }
}

const stateToProps = state => {
    return { people: state.calc.people.slice() };
}

const dispatchToProps = { personRemoved, personChanged }

export default connect(stateToProps, dispatchToProps)(PlayersArea);
