import React from 'react';
import { connect } from 'react-redux';

import './Input.css';

import { personAdded } from '../actions/calcActions';

import Page from './Page';
import Input from './Input';


class addPlayerDetails extends React.Component {
    constructor(props) {
        super(props);
        this.beerRef = React.createRef();
        this.foodRef = React.createRef();
        this.state = {
            beer_count: 0,
            food_price: 0
        }
    }

    cancel() {
        this.props.history.goBack();
    }

    add_player() {
        this.props.personAdded({
            name: this.props.match.params.name,
            beer_count: this.state.beer_count,
            food_price: this.state.food_price
        });
        this.props.history.push('/calc');
    }

    beer_changed(evt) {
        this.setState({ beer_count: Number(evt.target.value) || 0 });
    }

    food_changed(evt) {
        this.setState({ food_price: Number(evt.target.value.replace(',', '.')) || 0 });
    }

    componentDidMount() {
        const beerCount = this.beerRef.current.input.current;
        beerCount.focus();
    }

    render() {
        const name = this.props.match.params.name;
        return (
            <Page header={`${name} hinzufügen`} history={this.props.history}>
              <div className="style-add-player-abort">
                <Input ref={this.beerRef} background="# Kölsch" value={ this.state.beer_count || ''}
                 onChange={ (evt) => this.beer_changed(evt)}
                 type="number" step="1" larger="true" />
                <Input ref={this.foodRef} background="Speise- und Schnapskosten" value={ this.state.food_price || ''}
                 onChange={ (evt) => this.food_changed(evt)}
                 type="number" step="0.0001" larger="true" />
                <div className="style-2-bottom-buttons">
                  <button className="btn btn-large" onClick={ () => this.add_player() }>
                    Fertig
                  </button>
                  <button className="btn btn-large" onClick={ () => this.cancel() }>
                    Abbrechen
                  </button>
                </div>
              </div>
            </Page>
        )
    }
}

export default connect(null, { personAdded })(addPlayerDetails);
