import React from 'react';
import { connect } from 'react-redux';

import './Input.css';

import { personChanged, personRemoved } from '../actions/calcActions';

import Page from './Page';
import Input from './Input';

class editPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.player = this.props.people[this.id];
        if (this.player) {
            this.state = {
                beer_count: this.player.beer_count,
                food_price: this.player.food_price
            }
        }
    }

    edit_player() {
        this.props.personChanged(this.id, {
            name: this.player.name,
            beer_count: this.state.beer_count,
            food_price: this.state.food_price
        });
        this.props.history.push('/calc');
    }

    remove_player(id) {
        this.props.personRemoved(id);
        this.props.history.push('/calc');
    }

    beer_changed(evt) {
        this.setState({ beer_count: Number(evt.target.value) || 0 });
    }

    food_changed(evt) {
        this.setState({ food_price: Number(evt.target.value.replace(',', '.')) || 0 });
    }

    render() {
        if (this.player === undefined) {
            this.props.history.push('/calc');
            return '';
        }
        return (
            <Page header={`${this.player.name} bearbeiten`} history={this.props.history}>
              <div className="style-add-player-abort">
                <Input background="# Kölsch" value={ this.state.beer_count || ''}
                 onChange={ (evt) => this.beer_changed(evt)}
                 type="number" step="1" larger="true" />
                <Input background="Speise- und Schnapskosten" value={ this.state.food_price || ''}
                 onChange={ (evt) => this.food_changed(evt)}
                 type="number" step="0.0001" larger="true" />
                <div className="style-3-bottom-buttons">
                  <button className="btn btn-large" onClick={ () => this.edit_player() }>
                    Fertig
                  </button>
                  <button className="btn btn-large" onClick={ () => this.remove_player(this.id) }>
                    { this.player.name } entfernen
                  </button>
                  <button className="btn btn-large" onClick={ () => this.props.history.push('/calc') }>
                    Abbrechen
                  </button>
                </div>
              </div>
            </Page>
        )
    }
}

const stateToProps = state => {
    return { people: state.calc.people.slice() };
}

export default connect(stateToProps, {personChanged, personRemoved})(editPlayer);
