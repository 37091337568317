import React from 'react';
import { connect } from 'react-redux';

import './Calc.css';

import { personAdded } from '../actions/calcActions';
import Page from './Page';
import InputArea from './InputArea';
import PlayersArea from './PlayersArea';


class Calc extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick(evt) {
        this.props.history.push('/calc/add_player');
        // console.log(this.props.history);
        // this.props.personAdded({name: 'Willi', beer_count: 0, food_price: 0, payed: false})
    }

    render() {
        return (
         <Page header="Abrechnung" history={this.props.history}>
            <InputArea />
            <PlayersArea history={ this.props.history } />
            <div className="style-button-area">
              <button className="btn" onClick={ this.onClick }>Spieler hinzufügen</button>
            </div>
         </Page>
        )
    }
}

export default connect(null, {personAdded})(Calc)
