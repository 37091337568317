import './Header.css';
import '../App.css';

import React from 'react';

import Swipeable from './Swipeable';
import Notifications from './Notifications';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'

import Menu from './Menu';

export function Header(props) {
    return (
        <div className='style-header'>
            <FontAwesomeIcon
               icon={ faBars }
               className={"style-header-left" + (props.menuActive? " menu-button-active": '')}
               onClick={props.onMenuClick} />
            <div className="style-header-element style-header-center"> {props.children } </div>
            <FontAwesomeIcon icon={ faUserCircle } className="style-colored style-header-right"/>
        </div>
    );
}

export default class Page extends React.Component {
    constructor() {
        super()
        this.state = {
            swRegistered: false,
            menu: {
                isOpen: false,
                forceOpen: false,
                forceClose: false
            }
        }
        this.onMenuClick = this.onMenuClick.bind(this)
        this.toUrl = this.toUrl.bind(this)
        this.quit = this.quit.bind(this)
        this.setMenuState = this.setMenuState.bind(this)
    }

    setMenuState(newState, callback) {
        this.setState(oldState => {
            const newMenuState = {...oldState.menu, ...newState};
            return { menu: newMenuState }
        }, callback);
    }

    componentDidMount() {
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener("message", (evt) => {
              window.alert(`Nachricht vom SW: ${ evt.data }`);
            });
            setTimeout(() => this.checkWorkerReady(), 1);
        }
    }

    onMenuClick () {
        this.setMenuState({isOpen: true, forceOpen: true})
    }

    toUrl(url) {
        this.setMenuState({
            isOpen: false,
            forceOpen: false,
            forceClose: true
        }, () => {this.props.history.push(url); this.setMenuState({forceClose: false})})
    }

    checkWorkerReady() {
        if (navigator.serviceWorker) {
            navigator.serviceWorker.ready.then( reg => {
                if (reg.active) {
                    this.setState({swRegistered: true});
                }
            })
        }
    }

    quit() {
        window.close()
        window.stop()
    }

    render() {
        const menu = (
            <Menu
              swRegistered={this.state.swRegistered}
              quit={() => this.quit()}
              toUrl={this.toUrl} />
        )
        return (
            <Swipeable
                className="App"
                menuLeft={menu}
                menuLeftState={this.state.menu}
                setMenuLeftState={this.setMenuState}>
              <Notifications />
              <Header
                menuActive={this.state.menu.isOpen}
                onMenuClick={this.onMenuClick}>
                  { this.props.header}
              </Header>
              {this.props.children}
            </Swipeable>
        )
    }
}

