import React from 'react';
import { Provider } from 'react-redux';
import { MemoryRouter, Route, Redirect, Switch } from 'react-router-dom';

import store from './store';
import Calc from './components/Calc';
import addPlayer from './components/addPlayer';
import addGuest from './components/addGuest';
import addPlayerDetails from './components/addPlayerDetails';
import editPlayer from './components/editPlayer';

function App() {
    return (
       <Provider store={ store }>
        <MemoryRouter>
          <Switch>
            <Route path='/calc' exact component={ Calc } />
            <Route path='/calc/add_player' exact component={ addPlayer } />
            <Route path='/calc/add_guest' exact component={ addGuest } />
            <Route path='/calc/edit_player/:id' exact component={ editPlayer } />
            <Route path='/calc/add_player/:name' exact component={ addPlayerDetails } />
            <Route path='/'>
              <Redirect to='/calc' />
            </Route>
          </Switch>
        </MemoryRouter>
       </Provider>
    );
}

export default App;
