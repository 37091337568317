import React from 'react';
import { connect } from 'react-redux';

import { beerTotalChanged, skittleTotalChanged } from '../actions/calcActions';
import Input from './Input';

class InputArea extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(evt) {
        if (evt.target.name === "beer_total_price")  {
            this.props.beerTotalChanged(evt.target.value);
        } else {
            this.props.skittleTotalChanged(evt.target.value);
        }
    }

    render() {
        return (
          <div className="style-input-area">
            <div>
              <Input name="beer_total_price" background="Getränkepreis"
               variant="dark" type="number" step="0.0001"
               value={ this.props.beer_total_price } onChange={ this.onChange } />
              <Input name="skittle_total_price" background="Kegelbahn"
               variant="dark" type="number" step="0.0001"
               value={ this.props.skittle_total_price } onChange={ this.onChange } />
            </div>
            <div>
              <Input name="beer_price" background="Bier Einzelpreis"
               variant="light" readOnly={ true } step="0.0001"
               value={ this.props.beer_price } />
              <Input name="skittle_price" background="Kegelbahn p.P."
               variant="light" readOnly= { true } step="0.0001"
               value={ this.props.skittle_price } />
            </div>
            <div>
              <Input name="total_price" background="Gesamtsumme" variant="dark"
               readOnly={ true } larger={ true } step="0.0001"
               value={ this.props.total_price } />
            </div>
          </div>
        )
    }
}

const stateToProps = (state) => {
    return {
        beer_total_price: state.calc.beer_total_price || '',
        skittle_total_price: state.calc.skittle_total_price || '',
        beer_price: state.calc.beer_price || '',
        skittle_price: state.calc.skittle_price || '',
        total_price: state.calc.total_price || ''
    }
}

const dispatchToProps = { beerTotalChanged, skittleTotalChanged };

export default connect(stateToProps, dispatchToProps)(InputArea);
