import React from 'react';

export default function Menu(props) {
    const buttons = [
         <button key="1" className="btn" onClick={() => props.toUrl('/calc')}>Abrechnung</button>,
         <button key="2" className="btn" onClick={() => alert('TBD')}>Highscores</button>,
         <button key="3" className="btn" onClick={() => alert('TBD')}>Strafen</button>,
         <button key="4" className="btn" onClick={() => alert('TBD')}>Fotos / Videos</button>,
         <button key="5" className="btn" onClick={() => alert('TBD')}>Termine</button>,
    ]
    buttons.push(
        <button key="6" className="btn" onClick={() => props.quit()}>Beenden</button>
    )
    return (
        <div className="Menu">
            <div className="wrapper">
                { props.swRegistered? 'ServiceWorker registered': 'ServiceWorker not registered' }
                <div className="buttons">
                    { buttons }
                </div>
            </div>
        </div>
    )
}

