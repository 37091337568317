import React from 'react';

export default class Swipeable extends React.Component {
    constructor() {
        super();
        this.state = {
            touches: {},
            leftMenuPos: -70,
            leftMoving: undefined,
            opening: false,
        }
        this.container = React.createRef();
        this.touchStart = this.touchStart.bind(this);
        this.touchEnd = this.touchEnd.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.touchCancel = this.touchCancel.bind(this);
        this.closeLeftMenu = this.closeLeftMenu.bind(this);
    }

    touchStart(evt) {
        let changes = {};
        let leftMoving = null;
        let offset = 0
        let opening = true;
        for (let touch of evt.changedTouches) {
            let x = touch.pageX || touch.clientX;
            let y = touch.pageY || touch.clientY;
            changes[touch.identifier] = [x, y];
            if (this.state.leftMenuPos === 0) {
                offset = 70;
                opening = false;
            }
            if (offset > 0 || x < 40) {
                leftMoving = touch.identifier;
            }
        }
        if (leftMoving !== undefined) {
            this.setState(old => {
                if (leftMoving === null) {
                    leftMoving = old.leftMoving
                }
                return {
                    touches: {...old.touches, ...changes},
                    leftMoving: leftMoving,
                    leftMenuPos: -70 + offset,
                    opening: opening
                }
            })
            // this.props.setLeftMenu(true);
        }
    }

    getMenuOffset(touch) {
        const x = touch.pageX || touch.clientX;
        const percent = (x - this.state.touches[touch.identifier][0]) / (window.screen.width * 0.8);
        if (this.state.opening) {
            return Math.max(Math.min(-70 + (70 * percent), 0), -70);
        }
        return Math.min(Math.max(0 + (70 * percent), -70), 0)
    }

    touchMove(evt) {
        if (this.state.leftMoving !== undefined) {
            let touch = null;
            for (let i of evt.changedTouches) {
                if (i.identifier === this.state.leftMoving) {
                    touch = i;
                }
            }
            if (touch) {
                const offset = this.getMenuOffset(touch);
                if (offset < -3 && !this.props.menuLeftState.isOpen && this.state.opening) {
                    this.props.setMenuLeftState({isOpen: true});
                }
                this.setState(old => {
                    return {leftMenuPos: offset}
                })
            }
        }
    }

    touchEnd(evt) {
        let changedTouches = [];
        for (let touch of evt.changedTouches) {
            changedTouches.push(touch.identifier);
            if (touch.identifier === this.state.leftMoving) {
                let endPos = this.state.leftMenuPos > -35 ? 0: -70;
                this.setState({leftMenuPos: endPos, leftMoving: undefined});
                if (endPos < 0) {
                    this.props.setMenuLeftState({isOpen: false});
                }
            } else if (this.state.leftMoving === undefined) {
                this.props.setMenuLeftState({isOpen: false});
            }
        }
        this.setState(old => {
            let leftIds = Object.keys(old.touches).filter(id => {
                return changedTouches.indexOf(id) === -1
            })
            let remaining = {};
            for (let id of leftIds) {
                remaining[id] = old.touches[id];
            }
            return { touches: remaining }
        })
    }

    touchCancel(evt) {
        console.log('touch cancel event');
    }

    componentDidMount() {
        this.container.current.addEventListener('touchstart', this.touchStart);
        this.container.current.addEventListener('touchmove', this.touchMove);
        this.container.current.addEventListener('touchend', this.touchEnd);
        this.container.current.addEventListener('touchcancel', this.touchcancel);
    }

    componentDidUpdate(prevProps) {
        if (this.props.menuLeftState.forceOpen) {
            this.setState({leftMenuPos: 0});
            this.props.setMenuLeftState({isOpen: true, forceOpen:false });
        } else if (this.props.menuLeftState.forceClose) {
            this.setState({leftMenuPos: -70});
            this.props.setMenuLeftState({isOpen: false, forceOpen:false });
        }
    }

    closeLeftMenu() {
        this.setState({leftMenuPos: -70, leftMoving: undefined});
        this.props.setMenuLeftState({isOpen: false});
    }

    render() {
        let leftMenuContent = this.props.menuLeftState.isOpen ? this.props.menuLeft: null;
        let leftMenuStyle = {
            position: 'absolute',
            display: 'block',
            top: 0,
            left: this.state.leftMenuPos+"vw",
            zIndex: 100,
            width: "70vw",
            height: "100%",
        }
        const opacity = (70 + this.state.leftMenuPos) / 100;
        const darken = {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: this.props.menuLeftState.isOpen? 90 : -100,
            backgroundColor: `rgba(0, 0, 0, ${opacity}`,
        }
        return (
            <div
              className={this.props.className}
              ref={this.container}>
                <div style={leftMenuStyle} >{leftMenuContent}</div>
                <div className="darkenLayer" style={darken} onClick={this.closeLeftMenu}></div>
                {this.props.children}
            </div>
         )
    }
}
