import React from 'react';

import pushApi from '../tools/pushApi.js';

export default class Notifications extends React.Component {
    state = {
        unreadNotifications: [],
    }

    componentDidMount() {
        new pushApi(this.onPushRegister);
    }

    onPushRegister = (data) => {
        console.info('Subscribed successfully.')
        fetch(`/api/add_push_stuff`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                endpoint: data.endpoint,
                auth: data.keys.auth,
                p256dh: data.keys.p256dh
            })
        }).then(resp => resp.json()).then(data => console.log(data)).catch(err => console.log(err))
    }

    render() {
        if (this.state.unreadNotifications.length === 0) {
            return null;
        }
        const notifications = this.state.unreadNotifications.map(note => {
            return (
                <div className="notification" key={note.id}>
                    { JSON.stringify(note) }
                </div>
            )
        })
        return (
            <div className="notifications">
                 { notifications }
            </div>
        )
    }
}
