import { BEER_TOTAL_CHANGED, SKITTLE_TOTAL_CHANGED, PERSON_ADDED, PERSON_CHANGED, PERSON_REMOVED } from '../actions/types';

const initalState = {
    beer_total_price: '',
    skittle_total_price: '',
    beer_price: '',
    skittle_price: '',
    total_price: '',
    people: []
}

function as_price(number) {
    return Number(number.toFixed(2))
}

function calcTotalPrices(state) {
    const beer =  state.beer_total_price || 0;
    const skittle =  state.skittle_total_price || 0;
    state.total_price = as_price(beer + skittle) || '';
    if (state.people.length) {
        state.total_price = 0;
        for (let index in state.people) {
            let person = state.people[index];
            if (!person) {
                break
            }
            let beer_price = 0;
            let skittle_price = state.skittle_price || 0;
            if (state.beer_price) {
                beer_price = person.beer_count * state.beer_price
            }
            let food_price = person.food_price || 0;
            let total_price = as_price(beer_price + skittle_price + food_price);
            state.people[index].total_price = total_price || '';
            state.total_price = as_price(state.total_price + total_price);
        }
        if (!state.total_price) {
            state.total_price = '';
        }
    }
    return state;
}

function calcBeerPrice(state) {
    if (!state.beer_total_price) {
        state.beer_price = '';
    } else {
        let beer_count = 0;
        for (let person of state.people) {
            beer_count += person.beer_count || 0;
        }
        if (beer_count) {
            state.beer_price = as_price(state.beer_total_price / beer_count);
        } else {
            state.beer_price = '';
        }
    }
    return state;
}

function calcSkittlePrice(state) {
    if (!state.skittle_total_price) {
        state.skittle_price = '';
    } else {
        let people_count = state.people.length;
        if (people_count) {
            state.skittle_price = as_price(state.skittle_total_price / people_count);
        } else {
            state.skittle_price = '';
        }
    }
    return state
}

export default function calcReducer(state = initalState, action) {
    switch (action.type) {
        case BEER_TOTAL_CHANGED: {
            let hasPeople = state.people.length !== 0;
            let total_beer_price = Number(action.value.replace(',', '.')) || '';
            let newState = Object.assign({}, state);
            newState.beer_total_price = total_beer_price;
            if (hasPeople) {
                newState = calcBeerPrice(newState)
            }
            newState = calcTotalPrices(newState);
            return newState;
        }

        case SKITTLE_TOTAL_CHANGED: {
            let hasPeople = state.people.length !== 0;
            let total_skittle_price = Number(action.value.replace(',', '.')) || '';
            let newState = Object.assign({}, state);
            newState.skittle_total_price = total_skittle_price;
            if (hasPeople) {
                newState = calcSkittlePrice(newState);
            }
            newState = calcTotalPrices(newState);
            return newState;
        }

        case PERSON_ADDED: {
            let newState = Object.assign({}, state);
            newState.people.push(action.data);
            newState = calcBeerPrice(newState);
            newState = calcSkittlePrice(newState);
            newState = calcTotalPrices(newState);
            return newState;
        }

        case PERSON_CHANGED: {
            let newState = Object.assign({}, state);
            newState.people[action.index] = action.data;
            newState = calcBeerPrice(newState);
            newState = calcSkittlePrice(newState);
            newState = calcTotalPrices(newState);
            return newState;
        }

        case PERSON_REMOVED: {
            let newState = Object.assign({}, state);
            newState.people.splice(action.index, 1);
            newState = calcBeerPrice(newState);
            newState = calcSkittlePrice(newState);
            newState = calcTotalPrices(newState);
            return newState
        }

        default:
            return state
    }
}
