import { createStore, combineReducers } from 'redux';

import { calcReducer } from './reducers';

const initalState = {};

const rootReducer = combineReducers({ calc: calcReducer });

export default createStore(rootReducer, initalState);

