import React from 'react';
import { connect } from 'react-redux';

import '../App.css';
import './Header.css';

import Page from './Page';

class addPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            player_names: [
                'Axel', 'Bammer', 'Dennis', 'David', 'Fabi', 'Felix',
                'Hannes', 'Kolja R.', 'Kolja K.', 'Kornelius',
                'Lutz', 'Max', 'Mo', 'Paul', 'Roman E.', 'Roman K.'
            ]
        }
    }

   componentDidMount() {
       this.setState(old => {
           let usedNames = this.props.people.map(player => player.name);
           return { player_names: old.player_names.filter(name => usedNames.indexOf(name) === -1)}
       })
   }

    onClick(name) {
        this.props.history.push('/calc/add_player/' + name);
    }

    addGuest() {
        this.props.history.push('/calc/add_guest/');
    }

    cancel() {
        this.props.history.goBack();
    }

    render() {
        const players = this.state.player_names.map(name => {
            return (
              <button key={ name } className="btn" onClick={ () => this.onClick(name) }>
                { name }
              </button>
            )
        });
        return (
            <Page header="Spieler hinzufügen!" history={this.props.history}>
              <div className="style-add-player-buttons">
                 { players }
                 <button className="btn btn-large" onClick={ () => this.addGuest() }>
                   Gastkegler
                 </button>
              </div>
              <div className="style-add-player-abort">
                <button className="btn btn-large" onClick={ () => this.cancel() }>
                  Abbrechen
                </button>
              </div>
            </Page>
        )
    }
}


const stateToProps = state => {
    return { people: state.calc.people.slice() };
}


export default connect(stateToProps, {})(addPlayer);
