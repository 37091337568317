import React from 'react';

import './Input.css';

export default class Input extends React.Component {
    constructor(props) {
        super(props);
        this.state = { focus: false }
        this.larger = this.props.larger? ' style-input-larger': '';
        this.input = React.createRef();
    }

    setFocus(evt, value) {
        if (!value) {
            this.setState({focus: false});
        } else if (!evt.target.readOnly) {
            this.setState({focus: true});
        }
    }

    onKeyPress = (evt) => {
        if (evt.key === "Enter") {
            this.tabNext();
        }
    }

    tabNext() {
        const allElements = document.querySelectorAll('input, button, a, area, object, select, textarea, [contenteditable]');
        const currentIndex = [...allElements].findIndex(el => this.input.current.isEqualNode(el))
        const targetIndex = (currentIndex + 1) % allElements.length;
        allElements[targetIndex].focus();
    }

    render() {
        let active = '';
        if (this.props.value || this.state.focus) {
            active = ' style-input-active';
        }
        return (
            <span className={"style-input style-input-" + (this.props.variant || 'dark') + this.larger + active} >
               <input onKeyPress={this.onKeyPress} ref={this.input} name={ this.props.name || '' } type={ this.props.type || 'text' }
                value={ this.props.value } required={ true } onChange={ this.props.onChange }
                readOnly={ this.props.readOnly || false } step={ this.props.step }
                onFocus={ evt => {this.setFocus(evt, true) }} onBlur={ evt => this.setFocus(evt, false) } />
               <span className="style-input-background" >
                   <span className="style-input-label">{ this.props.background }</span>
               </span>
               <span className="style-input-space-blocker">&nbsp;</span>
            </span>
        );
    }
}
