import React from 'react';
import { connect } from 'react-redux';

import './Input.css';

import { personAdded } from '../actions/calcActions';

import Page from './Page';
import Input from './Input';


class addGuest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            beer_count: 0,
            food_price: 0
        }
    }

    cancel() {
        this.props.history.goBack();
    }

    add_player() {
        this.props.personAdded({
            name: this.state.name,
            beer_count: this.state.beer_count,
            food_price: this.state.food_price
        });
        this.props.history.push('/calc');
    }

    value_changed(key, evt) {
        let value = evt.target.value;
        if (evt.target.type === 'number') {
            value = Number(value) || 0
        }
        this.setState({ [key]: value });
    }

    render() {
        return (
            <Page header="Gast hinzufügen" history={this.props.history}>
              <div className="style-add-player-abort">
                <Input background="Name" value={ this.state.name || ''}
                 onChange={ (evt) => this.value_changed('name', evt) }
                 type="name" larger="true" />
                <Input background="# Kölsch" value={ this.state.beer_count || ''}
                 onChange={ (evt) => this.value_changed('beer_count', evt) }
                 type="number" step="1" larger="true" />
                <Input background="Speise- und Schnapskosten" value={ this.state.food_price || ''}
                 onChange={ (evt) => this.value_changed('food_price', evt) }
                 type="number" step="0.0001" larger="true" />
                <div className="style-3-bottom-buttons">
                  <button className="btn btn-large" onClick={ () => this.add_player() }>
                    Fertig
                  </button>
                  <button className="btn btn-large" onClick={ () => this.cancel() }>
                    Abbrechen
                  </button>
                </div>
              </div>
            </Page>
        )
    }
}

export default connect(null, { personAdded })(addGuest);
