export default function PushApi(callback) {
    this.subscription = null;
    this.register().then(registration => {
        this.registration = registration;
        this.subscribePush(callback);
    }).catch(err => {console.error(err); return});
}

PushApi.prototype.register = function() {
    return new Promise((resolve, reject) => {
        if (Notification.permission === 'denied') {
            reject('User has blocked push notification');
        }
        if (!('PushManager' in window)) {
            reject('This browser seems to have no Push Notifications support');
        }
        navigator.serviceWorker.ready.then(reg => {
            reg.pushManager.getSubscription().then(sub => {
                this.subscription = sub;
                resolve(reg);
            });
        }).catch(err => reject('Error occured while enabling push ', err));
    })
}

PushApi.prototype.subscribePush = function(callback) {
    if (this.registration === undefined) {
        console.warn('subscribePush before registration')
    }
    if (!this.registration.pushManager) {
        console.warn('Your browser doesn\'t support push notification.');
        return false;
    }
    this.registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: "BEk8YHv9s41beRPlynFxLQJ4ctK9OdP-tpFXHzmIBlCVpsr570ztkcTUsV0Hz1wETSwgf6ZgQ1vaGr-RSCBz6so",
    }).then(sub => {
        this.subscribtion = sub;
        let data = sub.toJSON();
        callback(data);
    }).catch(err => console.error('Push notificiton subscription error ', err));
}

