import { BEER_TOTAL_CHANGED, SKITTLE_TOTAL_CHANGED, PERSON_ADDED, PERSON_CHANGED, PERSON_REMOVED } from './types';

export function beerTotalChanged(beerTotal) {
    return {
        type: BEER_TOTAL_CHANGED,
        value: beerTotal
    }
}

export function skittleTotalChanged(skittleTotal) {
    return {
        type: SKITTLE_TOTAL_CHANGED,
        value: skittleTotal
    }
}

export function personAdded(data) {
    return {
        type: PERSON_ADDED,
        data: data
    }
}

export function personChanged(index, data) {
    return {
        type: PERSON_CHANGED,
        index: index,
        data: data
    }
}

export function personRemoved(index) {
    return {
        type: PERSON_REMOVED,
        index: index,
    }
}
